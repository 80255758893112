import request from '../request'

//差旅申请列表
export const travel_index_request = p => {
    return request({
        method:'GET',
        url:'travel/index',
        params: p
    })
}

//发起差旅申请
export const travel_add_request = d => {
    return request({
        method:'POST',
        url:'travel/add',
        data: d
    })
}

//删除差旅申请
export const travel_del_request = id => {
    return request({
        method:'DELETE',
        url:'travel/del',
        data: {
            id: id
        }
    })
}

//修改差旅申请
export const travel_edit_request = data => {
    return request({
        method:'PUT',
        url:'travel/edit',
        data
    })
}

//获取差旅申请详情
export const travel_details_request = id => {
    return request({
        method:'GET',
        url:'travel/details',
        params: {
            id
        }
    })
}

// 提交差旅申请
export const travel_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'travel/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看差旅申请审批记录
export const travel_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'travel/sel_approval',
        params: {
            id
        }
    })
}

// 审批差旅申请
export const travel_approval_request = d => {
    return request({
        method:'PUT',
        url:'travel/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回差旅申请
export const travel_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'travel/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印差旅申请
export const travel_print_request = id => {
    return request({
        method:'PUT',
        url:'travel/print',
        data: {
            id
        }
    })
}

// 补充差旅申请附件
export const travel_add_file_request = data => {
    return request({
        method:'PUT',
        url:'travel/add_file',
        data
    })
}