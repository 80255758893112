<template>
    <div>
        <el-dialog
            :visible.sync="show_print"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => $emit('exit_print')"
        >
            <div ref="PrintTravel">
                <p style="text-align: center;">
                    <b>
                        <span style="font-size:18px;">{{data | title_name}}</span>
                    </b>
                </p>
                <table style="font-family: Microsoft YaHei;font-size:15px;width: 100%;border: 1px solid black;">
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请部门</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.enterprise_name+'/'+data.department_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">申请人</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.staff_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">随行人员</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.entourage_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">差旅标题</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.title}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">差旅原由</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.content}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">行程安排</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.trip}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">目的地</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.address_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">差旅时长</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.days+'天'}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">开始日期</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.start_time}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">结束日期</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.end_time}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">差旅预算</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.travel_money | diy_money}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">是否需要招待</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.contain_entertain ? '需要' : '不需要'}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.contain_entertain">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">招待目的</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.entertain_content}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.contain_entertain">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">物资需求</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.entertain_goods}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.contain_entertain">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">招待日期</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.entertain_date}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">招待客户</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.entertain_client_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.contain_entertain">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">招待预算</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.entertain_money | diy_money}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">预计人数</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.entertain_client_num + '人'}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">费用事项</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.money_note}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">预算总金额</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.money | diy_money}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">审批人</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{examine}}</td>
                    </tr>
                </table>
                <div v-for="(iteam,index) in data.print" :key="iteam.time" style="font-family: Microsoft YaHei;font-size:15px;">
                    {{'【历史】此差旅申请为第'+(index+1)+'次打印，打印人：'+iteam.staff+'，打印时间：'+iteam.time}}
                </div>
                <div style="font-family: Microsoft YaHei;font-size:15px;">
                    {{'【当前】此差旅申请为第'+(data.print.length+1)+'次打印，打印人：'+$store.state.user.name+'，打印时间：' + NowTime}}
                </div>
                <br/>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    plain
                    type="primary"
                    @click="printBtn('PrintTravel')"
                    >打印</el-button
                >
                <el-button
                    plain
                    @click="() => $emit('exit_print')"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { fomatFloat,thousandBitSeparator,now } from '@/assets/js/common.js'
import { travel_print_request } from '@/network/finance/travel.js'

export default {
    name:'TravelPrintComponent',
    data(){
        return {
            examine: '',
            NowTime: ''
        }
    },
    computed:{},
    methods:{
        printBtn(formRef) {
            this.login_loading = true
            travel_print_request(this.data.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$print(this.$refs[formRef])
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{
        diy_money(v) {
            return '￥'+ thousandBitSeparator(fomatFloat(v))
        },
        title_name(v) {
            let a = '差旅单：'+v.name+' '
            if(v.state === 0) {
                a += '【待提交】'
            } else if(v.state === 1) {
                a += '【审批中】'
            } else if(v.state === 2) {
                a += '【已通过】'
            } else if(v.state === 3) {
                a += '【已拒绝】'
            }

            return a
        }
    },
    props:{
        show_print: {
            type: Boolean,
            default() {
                return false
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        ApprovalData: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){
        this.NowTime = now()
    },
    components:{},
    watch:{
        ApprovalData(newVal) {
            this.examine = ''
            newVal.forEach(v => {
                if(v.start_staff_id) {
                    this.examine = v.start_staff_name+'(发起)'
                }
                if(v.staff_id === -1) {
                    this.examine += '→'+v.staff_name+'(撤回)'
                }
                if((v.staff_id>0) && (!v.time)) {
                    this.examine += '→'+v.staff_name+'(待审批)'
                }
                if(v.opinion) {
                    this.examine += '→'+v.staff_name+'('+v.opinion+')'
                }
                if(v.staff_id === 0) {
                    this.examine += '→流程结束'
                }
            });
        }
    }
}
</script>

<style>
</style>