<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="70%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog('TravelEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="travel.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="travel.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="travel.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="travel.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(travel.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="travel"
                    :rules="rules"
                    ref="TravelEditForm"
                    label-width="120px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-form-item label="申请部门" prop="department_id">
                                <el-cascader
                                    v-model="travel.department_id"
                                    :options="enterprise_department"
                                    :props="{ expandTrigger: 'hover' }"
                                    clearable
                                    style="width: 100%"
                                >
                                </el-cascader>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item  label="申请人" prop="staff_id">
                                        <el-select
                                            v-model="travel.staff_id"
                                            filterable
                                            clearable
                                            placeholder="选择申请人"
                                            class="staff_idc"
                                        >
                                            <el-option
                                                v-for="item in AllStaff"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="随行人" prop="entourage_id">
                                        <el-select
                                            v-model="travel.entourage_id"
                                            filterable
                                            multiple
                                            placeholder="选择随行人"
                                            class="entourage_idc"
                                        >
                                            <el-option
                                                v-for="item in AllStaff"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">差旅信息</legend>
                            <el-form-item label="差旅标题" prop="title">
                                <el-input v-model="travel.title" clearable ></el-input>
                            </el-form-item>
                            <el-form-item label="差旅原由" prop="content">
                                <el-input type="textarea" :rows="3" v-model="travel.content"></el-input>
                            </el-form-item>
                            <el-form-item label="行程安排" prop="trip">
                                <el-input type="textarea" :rows="3" v-model="travel.trip"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="目的地" prop="address_id">
                                        <el-select
                                            v-model="travel.address_id"
                                            filterable
                                            placeholder="选择目的地"
                                            class="address_idc"
                                        >
                                            <el-option
                                                v-for="item in AllAddress"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="差旅天数" prop="days">
                                        <el-input-number
                                            v-model="travel.days"
                                            :min="1"
                                            :step="0.5"
                                            :precision="1"
                                            :step-strictly="true"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="开始日期" prop="start_time">
                                        <el-date-picker
                                            v-model="travel.start_time"
                                            align="right"
                                            type="date"
                                            placeholder="选择日期"
                                            value-format="yyyy-MM-dd"
                                            class="start_timec"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="结束日期" prop="end_time">
                                        <el-date-picker
                                            v-model="travel.end_time"
                                            align="right"
                                            type="date"
                                            placeholder="选择日期"
                                            value-format="yyyy-MM-dd"
                                            class="start_timec"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="差旅预算" prop="travel_money">
                                        <el-input-number
                                            v-model="travel.travel_money"
                                            :min="0"
                                            :step="1"
                                            :precision="2"
                                            :step-strictly="false"
                                            size="small"
                                            class="input_number"
                                            @change="sum_money"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                        <el-form-item label="是否需要招待" prop="contain_entertain">
                                        <el-radio-group v-model="travel.contain_entertain" size="medium" :fill="contain_entertain_color" @change="edit_radio">
                                            <el-radio-button :label="0">否</el-radio-button>
                                            <el-radio-button :label="1">是</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc" v-if="travel.contain_entertain">
                            <legend class="legendc">招待信息</legend>
                            <el-form-item label="招待目的" prop="entertain_content">
                                <el-input type="textarea" :rows="3" v-model="travel.entertain_content"></el-input>
                            </el-form-item>
                            <el-form-item label="物资需求" prop="entertain_goods">
                                <el-input type="textarea" :rows="3" v-model="travel.entertain_goods"></el-input>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="招待日期" prop="entertain_date">
                                        <el-date-picker v-model="travel.entertain_date" align="right" type="date"
                                            placeholder="选择日期" value-format="yyyy-MM-dd" class="entertain_datec">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                 <el-col :span="12">
                                     <el-form-item label="招待客户" prop="entertain_client_id">
                                        <el-select v-model="travel.entertain_client_id" filterable clearable placeholder="选择客户"
                                            style="width:100%">
                                            <el-option v-for="item in AllClient" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="预计人数" prop="entertain_client_num">
                                        <el-input-number
                                            v-model="travel.entertain_client_num"
                                            :min="1"
                                            :step="1"
                                            :precision="0"
                                            :step-strictly="true"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="招待预算" prop="entertain_money">
                                        <el-input-number
                                            v-model="travel.entertain_money"
                                            :min="0"
                                            :step="1"
                                            :precision="2"
                                            :step-strictly="false"
                                            size="small"
                                            class="input_number"
                                            @change="sum_money"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset  class="fieldsetc bottomc">
                            <legend class="legendc">费用信息</legend>
                            <el-form-item label="费用事项" prop="money_note">
                                <el-input type="textarea" :rows="3" v-model="travel.money_note"></el-input>
                            </el-form-item>
                            <el-form-item label="预算总金额" prop="money">
                                <span style="color: #2828FF;">{{travel.money | fomatFloatMoney}}</span>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="travel.other"
                            upload_url="travel/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请部门</span>
                                    </template>
                                    <span>{{travel.enterprise_name ? travel.enterprise_name + '/' + travel.department_name : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{travel.staff_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>随行人</span>
                                    </template>
                                    <span>{{travel.entourage_name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">差旅信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>差旅标题</span>
                                    </template>
                                    <span>{{travel.title}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>差旅原由</span>
                                    </template>
                                    <span>{{travel.content}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>行程安排</span>
                                    </template>
                                    <span>{{travel.trip}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>目的地</span>
                                    </template>
                                    <span>{{travel.address_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>差旅时长</span>
                                    </template>
                                    <span>{{travel.days ? travel.days + '天' : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>开始日期</span>
                                    </template>
                                    <span>{{travel.start_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>结束日期</span>
                                    </template>
                                    <span>{{travel.end_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>差旅预算</span>
                                    </template>
                                    <span>{{travel.travel_money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>是否需要招待</span>
                                    </template>
                                    <el-tag v-if="travel.contain_entertain" type="success">需要</el-tag>
                                    <el-tag v-else type="info">不需要</el-tag>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc" v-if="travel.contain_entertain">
                            <legend class="legendc">招待信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>招待目的</span>
                                    </template>
                                    <span>{{travel.entertain_content}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>物资需求</span>
                                    </template>
                                    <span>{{travel.entertain_goods}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item >
                                    <template slot="label">
                                        <span>招待日期</span>
                                    </template>
                                    <span>{{travel.entertain_date}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item >
                                    <template slot="label">
                                        <span>招待客户</span>
                                    </template>
                                    <span>{{travel.entertain_client_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>预计人数</span>
                                    </template>
                                    <span>{{travel.entertain_client_num +'人'}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>招待预算</span>
                                    </template>
                                    <span>{{travel.entertain_money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset  class="fieldsetc bottomc">
                            <legend class="legendc">费用信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="1"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>费用事项</span>
                                    </template>
                                    <span>{{travel.money_note}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align': 'center','color':'#2828FF'}">
                                    <template slot="label">
                                        <span>预算总金额</span>
                                    </template>
                                    <span>{{travel.money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="travel.other.length"
                            ref="upload"
                            :ffileList="travel.other"
                            upload_url="travel/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="travel/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <common-budget-component
                v-if="budget_count.all_quota"
                :budget_obj="budget_count"
            ></common-budget-component>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval('TravelEditForm')"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="travelWithdraw('PurchaseEditForm')"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="$emit('show_edit')"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="travel_del('TravelEditForm')"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closeDialog('TravelEditForm')"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('TravelEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('TravelEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('TravelEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('TravelEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('TravelEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="travel.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="TravelApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <travel-print-component
                    :show_print="show_print"
                    :data="travel"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </travel-print-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import TravelPrintComponent from './TravelPrintComponent'
import CommonBudgetComponent from '@/components/common/CommonBudgetComponent'

import {
    travel_add_request,
    travel_del_request,
    travel_edit_request,
    travel_to_approval_request,
    travel_sel_approval_request,
    travel_approval_request,
    travel_withdraw_request,
    travel_add_file_request
} from '@/network/finance/travel.js'
import { staff_list_request } from '@/network/list.js'
import { thousandBitSeparator,fomatFloat } from '@/assets/js/common.js'

export default {
    name: 'TravelEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,            
            travel: {
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                staff_id: this.$store.state.user.id,
                entourage_id: [],
                title: '',
                content: '',
                trip: '',
                address_id: '',
                days: '',
                start_time: '',
                end_time: '',
                travel_money: '',
                contain_entertain: '',
                entertain_content: '',
                entertain_date: '',
                entertain_client_num: '',
                entertain_goods: '',
                entertain_money: '',
                entertain_client_id:'',
                money_note: '',
                money: 0,
                other: [],
                print: []
            },
            rules: {
                department_id: [
                    {
                        required: true,
                        message: '请选择申请部门',
                        trigger: 'blur',
                    },
                ],
                staff_id: [
                    {
                        required: true,
                        message: '请选择申请人',
                        trigger: 'blur',
                    },
                ],
                title: [
                    {
                        required: true,
                        message: '请输入差旅标题',
                        trigger: 'blur',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请输入差旅原由',
                        trigger: 'blur',
                    },
                ],
                trip: [
                    {
                        required: true,
                        message: '请输入行程安排',
                        trigger: 'blur',
                    },
                ],
                address_id: [
                    {
                        required: true,
                        message: '请选择目的地',
                        trigger: 'blur',
                    },
                ],
                days: [
                    {
                        required: true,
                        message: '请输差旅时长',
                        trigger: 'blur',
                    },
                ],
                start_time: [
                    {
                        required: true,
                        message: '请选择开始时间',
                        trigger: 'blur',
                    },
                ],
                end_time: [
                    {
                        required: true,
                        message: '请选择结束时间',
                        trigger: 'blur',
                    },
                ],
                travel_money: [
                    {
                        required: true,
                        message: '请填写差旅预算',
                        trigger: 'blur',
                    },
                ],
                contain_entertain: [
                    {
                        required: true,
                        message: '请选择是否需要招待',
                        trigger: 'blur',
                    },
                ],
                money_note: [
                    {
                        required: true,
                        message: '请填写花费事项',
                        trigger: 'blur',
                    },
                ],
                money: [
                    {
                        required: true,
                        message: '请输入预计花费',
                        trigger: 'blur',
                    },
                ],
                entertain_client_id: [
                    {
                        required: true,
                        message: '请输入招待客户',
                        trigger: 'blur',
                    },
                ],   
                entertain_client_num: [
                    {
                        required: true,
                        message: '请输入预计人数',
                        trigger: 'blur',
                    },
                ],
                entertain_money: [
                    {
                        required: true,
                        message: '请输入招待预算',
                        trigger: 'blur',
                    },
                ],
                entertain_date: [
                    {
                        required: true,
                        message: '请输入招待日期',
                        trigger: 'blur',
                    },
                ],
                entertain_content: [
                    {
                        required: true,
                        message: '请输入招待目的',
                        trigger: 'blur',
                    },
                ],
                entertain_goods: [
                    {
                        required: true,
                        message: '请输入招待物资',
                        trigger: 'blur',
                    },
                ]
            },
            AllStaff: [],
            ApprovalData: [],
            TravelApprovalRequest:travel_approval_request,
            show_print: false,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.travel.name ? '差旅单：' + this.travel.name : '差旅单详情'
            } else if (this.show_type === 1) {
                return '添加差旅申请'
            } else {
                return '修改差旅申请'
            }
        },
        contain_entertain_color() {
            if(this.travel.contain_entertain === 1) {
                return '#67c23a'
            }
            if(this.travel.contain_entertain === 0) {
                return '#409eff;'
            }
            
            return '#FFFFFF'
        }
    },
    methods: {
        closeDialog(formName) {
            this.travel = {
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                staff_id: this.$store.state.user.id,
                entourage_id: [],
                title: '',
                content: '',
                trip: '',
                address_id: '',
                days: '',
                start_time: '',
                end_time: '',
                travel_money: '',
                contain_entertain: '',
                entertain_content: '',
                entertain_date: '',
                entertain_client_num: '',
                entertain_goods: '',
                entertain_money: '',
                entertain_client_id:'',
                money_note: '',
                money: 0,
                other: [],
                print: []
            }
            this.$emit('exitTravelDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.travelInsert(formName)
            })
        },
        travelInsert(formName) {
            this.login_loading = true
            let d = this.travel
            d.department_id = d.department_id[d.department_id.length - 1]
            d.other = this.$refs.upload.fileList
            travel_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitTravelDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.travel = {
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                staff_id: this.$store.state.user.id,
                entourage_id: [],
                title: '',
                content: '',
                trip: '',
                address_id: '',
                days: '',
                start_time: '',
                end_time: '',
                travel_money: '',
                contain_entertain: '',
                entertain_content: '',
                entertain_date: '',
                entertain_client_num: '',
                entertain_goods: '',
                entertain_money: '',
                entertain_client_id:'',
                money_note: '',
                money: 0,
                other: [],
                print: []
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.travelEdit(formName)
            })
        },
        travelEdit(formName) {
            this.login_loading = true
            this.travel.id = this.id
            this.travel.department_id = this.travel.department_id[this.travel.department_id.length - 1]
            this.travel.other = this.$refs.upload.fileList
            travel_edit_request(this.travel)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitTravelDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.travel.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        travel_del(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    travel_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                })
                .catch((err) => {})
        },
        get_all_list() {
            staff_list_request({})
                .then((s) => {
                    this.AllStaff = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    travel_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            travel_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        travelWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    travel_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            travel_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        edit_radio(e) {
            if(e===0) {
                this.travel.entertain_content = ''
                this.travel.entertain_date = ''
                this.travel.entertain_client_num = ''
                this.travel.entertain_goods = ''
                this.travel.entertain_money = ''
                this.travel.entertain_client_id= ''
                this.sum_money()
            }
        },
        sum_money() {
            this.travel.money = this.travel.travel_money*1 + this.travel.entertain_money*1
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            travel_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {
        fomatFloatMoney(data) {
            return data !=='' ? thousandBitSeparator(fomatFloat(data)) + '元' : ''
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        travel_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        enterprise_department: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        AllClient: {
            type: Array,
            default() {
                return []
            },
        },
        AllAddress:{
            type:Array,
            default(){
                return []
            }
        },
        print_auth: String,
        budget_count: {
            type: Object,
            default() {
                return {
                    all_quota: 0,
                    use_quota: 0,
                    per_quota: 0,
                    entertain_quota: 0,
                    travel_quota: 0,
                    other_quota: 0
                }
            }
        }
    },
    created() {
        this.get_all_list()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        TravelPrintComponent,
        CommonBudgetComponent
    },
    watch: {
        travel_details_data(newVal) {
            this.travel = newVal
            this.travel.id = this.id
        },
    },
}
</script>

<style lang='less'>
.bottomc {
    margin-bottom: 10px;
}
.travel_datec {
    width: 100%;
}
.staff_idc {
    width: 100%;
}
.address_idc {
    width: 100%;
}
.entourage_idc {
    width: 100%;
}
.el-date-editor.el-input {
    width: 100%;
}
.travelmoneyc {
    width: 100%;
}
.input_number {
    width: 100%;
}
</style>