import request from '../request'

//预算使用明细列表
export const budget_use_index_request = p => {
    return request({
        method:'GET',
        url:'budget/use_index',
        params: p
    })
}

//预算额度使用情况
export const budget_count_request = data => {
    return request({
        method:'GET',
        url:'budget/budget_count',
        params: data
    })
}

//导出预算使用明细
export const out_excel_request = data => {
    return request({
        method:'GET',
        url:'budget/out_excel',
        params: data
    })
}